import { version } from '../../package.json';

const isProd = location.hostname === 'razorpay.com';

if ('SENTRY_DSN' !== 'false') {
  Sentry.init({
    dsn: 'SENTRY_DSN',
    environment: isProd ? 'production' : 'staging',
    release: version
  });
}
